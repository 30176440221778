<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch">
            <el-form-item label="菜单名称" prop="menuName">
                <el-input
                    v-model="queryParams.menuName"
                    placeholder="请输入菜单名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select v-model="queryParams.status" placeholder="菜单状态" clearable size="small">
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.dictValue"
                        :label="dict.dictLabel"
                        :value="dict.dictValue"
                    />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb20">
            <el-col :span="1.5">
                <el-button
                        type="primary"
                        plain
                        icon="el-icon-plus"
                        size="mini"
                        v-if="$app.haveRole('system:menu:add')"
                        @click="handleAdd"
                >新增</el-button>
            </el-col>
<!--            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>-->
        </el-row>

        <el-table
                v-loading="loading"
                :data="menuList"
                row-key="id"
                :size="$store.getters.size"
                :tree-props="{children: 'children'}"
        >
            <el-table-column prop="menuName" label="菜单名称" :show-overflow-tooltip="true" width="160"></el-table-column>
            <el-table-column prop="icon" label="图标" align="center" width="100">
                <template slot-scope="scope" v-if="scope.row.icon">
                    <i v-if="hasSvg(scope.row)" :class="scope.row.icon"></i>
                    <svg-icon v-else :icon-class="scope.row.icon" />
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序" width="60"></el-table-column>
            <el-table-column prop="menuAuth" label="权限标识" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="path" label="路由地址" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="status" label="状态" :formatter="visibleFormat" width="80"></el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini"
                               type="text"
                               icon="el-icon-edit"
                               v-if="$app.haveRole('system:menu:edit')"
                               @click="handleUpdate(scope.row)"
                    >修改</el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-plus"
                            v-if="$app.haveRole('system:menu:add')"
                            @click="handleAdd(scope.row)"
                    >新增</el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            v-if="$app.haveRole('system:menu:delete')"
                            @click="handleDelete(scope.row)"
                    >删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 添加或修改菜单对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body :close-on-click-modal="false">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上级菜单">
                            <treeselect
                                    v-model="form.parentId"
                                    :options="getTreeOptions()"
                                    :normalizer="normalizer"
                                    :show-count="true"
                                    placeholder="选择上级菜单"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="菜单类型" prop="menuType">
                            <el-radio-group v-model="form.menuType">
                                <el-radio v-for="(item, index) in radioOptions" :key="index" :label="item.value">{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item v-if="form.menuType !== 3" label="菜单图标">
                            <el-popover
                                    placement="bottom-start"
                                    width="460"
                                    trigger="click"
                                    @show="$refs['iconSelect'].reset()"
                            >
                                <IconSelect ref="iconSelect" @selected="selected" />
                                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                                    <svg-icon
                                            v-if="form.icon"
                                            slot="prefix"
                                            :icon-class="form.icon"
                                            class="el-input__icon"
                                            style="height: 43px;width: 16px;"
                                    />
                                    <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                                </el-input>
                            </el-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="菜单名称" prop="menuName">
                            <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="显示排序" prop="orderNum">
                            <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item v-if="form.menuType !== 3" prop="path">
                            <span slot="label">
                                <el-tooltip content="访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                路由地址
                            </span>
                            <el-input v-model="form.path" placeholder="请输入路由地址" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.menuType === 2">
                        <el-form-item prop="component">
                            <span slot="label">
                                <el-tooltip content="访问的组件路径，如：`system/user/index`，默认在`views`目录下" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                组件路径
                            </span>
                            <el-input v-model="form.component" placeholder="请输入组件路径" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.menuType !== 1">
                            <el-input v-model="form.perms" placeholder="请输入权限标识" maxlength="100" />
                            <span slot="label">
                                <el-tooltip content="控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                权限字符
                            </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.menuType !== 3">
                            <span slot="label">
                                <el-tooltip content="选择隐藏则路由将不会出现在侧边栏，但仍然可以访问" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                显示状态
                            </span>
                            <el-radio-group v-model="form.visible">
                                <el-radio
                                        v-for="dict in visibleOptions"
                                        :key="dict.dictValue"
                                        :label="dict.dictValue"
                                >{{dict.dictLabel}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="form.menuType !== 3">
                            <span slot="label">
                                <el-tooltip content="选择停用则路由将不会出现在侧边栏，也不能被访问" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                菜单状态
                            </span>
                            <el-radio-group v-model="form.status">
                                <el-radio
                                        v-for="dict in statusOptions"
                                        :key="dict.dictValue"
                                        :label="dict.dictValue"
                                >{{dict.dictLabel}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button :size="$store.getters.size" type="primary" @click="submitForm" :loading="saveLoading">确 定</el-button>
                <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { listMenu, getMenu, delMenu, addMenu, updateMenu } from "@/api/system/menu";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import IconSelect from "@/components/IconSelect";

    export default {
        name: "Menu",
        components: { Treeselect, IconSelect },
        data() {
            return {
                // 遮罩层
                loading: false,
                // 保存按钮
                saveLoading: false,
                // 显示搜索条件
                showSearch: true,
                // 菜单表格树数据
                menuList: [],
                // 菜单树选项
                menuOptions: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 显示状态数据字典
                visibleOptions: [{dictValue: 1, dictLabel: '显示'}, {dictValue: 2, dictLabel: '不显示'}],
                // 菜单状态数据字典
                statusOptions: [{dictValue: 1, dictLabel: '正常'}, {dictValue: 2, dictLabel: '禁用'}],
                radioOptions: [{name: '目录', value: 1},{name: '菜单', value: 2},{name: '按钮', value: 3}],
                // 查询参数
                queryParams: {
                    menuName: undefined,
                    visible: undefined
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {
                    menuName: [
                        { required: true, message: "菜单名称不能为空", trigger: "blur" }
                    ],
                    orderNum: [
                        { required: true, message: "菜单顺序不能为空", trigger: "blur" }
                    ],
                    path: [
                        { required: true, message: "路由地址不能为空", trigger: "blur" }
                    ]
                }
            };
        },
        created() {
            this.getList();
        },
        methods: {
            // 选择图标
            selected(name) {
                this.form.icon = name;
            },
            /** 查询菜单列表 */
            getList() {
                if(this.loading){return;}
                this.loading = true;
                listMenu(this.queryParams).then(response => {
                    this.menuList = response.data;
                    this.loading = false;
                });
            },
            /** 转换菜单数据结构 */
            normalizer(node) {
                if (node.children && !node.children.length) {
                    delete node.children;
                }
                return {
                    id: node.id,
                    label: node.menuName,
                    children: node.children
                };
            },
            getTreeOptions(){
                return [{ id: 0, menuName: '主类目', children: this.menuList }];
            },
            /** 查询菜单下拉树结构 */
            getTreeselect() {
                this.menuOptions = [];
                const menu = { id: 0, title: '主类目', children: [] };
                this.menuOptions.push(menu);
                // listMenu().then(response => {
                //     this.menuOptions = [];
                //     const menu = { menuId: 0, menuName: '主类目', children: [] };
                //     menu.children = this.handleTree(response.data, "menuId");
                //     this.menuOptions.push(menu);
                // });
            },
            // 显示状态字典翻译
            visibleFormat(row, column) {
                if (row.menuType === 3) {
                    return "";
                }
                return (row.isShow===1?'显示':'隐藏')
            },
            // 菜单状态字典翻译
            statusFormat(row, column) {
                if (row.menuType === 3) {
                    return "";
                }
                return "";
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    menuId: undefined,
                    parentId: 0,
                    menuName: undefined,
                    icon: undefined,
                    menuType: 1,
                    perms: undefined,
                    path: undefined,
                    orderNum: undefined,
                    visible: 1,
                    status: 1
                };
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.handleQuery();
            },
            /** 新增按钮操作 */
            handleAdd(row) {
                this.reset();
                this.getTreeselect();
                if (row != null && row.id) {
                    this.form.parentId = row.id;
                } else {
                    this.form.parentId = 0;
                }
                this.open = true;
                this.title = "添加菜单";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                this.getTreeselect();
                getMenu(row.guid).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改菜单";
                });
            },
            /** 提交按钮 */
            submitForm: function() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        this.saveLoading = true;
                        if (this.form.menuId !== undefined) {
                            updateMenu(this.form).then(response => {
                                this.saveLoading = false;
                                this.$message.success("修改成功");
                                this.open = false;
                                this.getList();
                            }).catch(()=>{this.saveLoading = false});
                        } else {
                            addMenu(this.form).then(response => {
                                this.saveLoading = false
                                this.$message.success("新增成功");
                                this.open = false;
                                this.getList();
                            }).catch(()=>{this.saveLoading = false});
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                this.$confirm('是否确认删除名称为"' + row.menuName + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return delMenu(row.guid);
                }).then(() => {
                    this.getList();
                    this.$message.success("删除成功");
                }).catch(() => {});
            },
            /** 判断是不是svg图标 */
            hasSvg: function (row) {
                return row.icon.indexOf('el') > -1;
            }
        }
    };
</script>
